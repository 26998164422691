<template>
  <v-container fluid class="pa-0">
    <v-progress-circular v-if="loading" :size="50" color="primary" indeterminate class="progress"></v-progress-circular>
    <v-form v-else :readonly="!canEditSettings">
      <v-card-title>Display or Hide Form Fields</v-card-title>
      <template>
        <v-expansion-panels popout class="my-2">
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              General
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12">
                  <v-subheader>Display Staff Only Category?</v-subheader>
                  <v-radio-group v-model="display.staffCategory" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Leave / Return
            </v-expansion-panel-header>
            <v-expansion-panel-content dense>
              <v-row dense class="my-2">
                <v-col cols="12">
                  <v-row v-if="me.is.superAdmin">
                    <v-col cols="12" md="4">
                      <v-subheader>Display Recurring Identical Trips button on the trip form?</v-subheader>
                      <v-radio-group v-model="display.recurringTrips" row class="ml-4">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col v-if="display.recurringTrips" cols="12" md="4">
                      <v-subheader>Display Recurring Identical Trips to admins only?</v-subheader>
                      <v-radio-group v-model="display.showRecurringTripsOnlyToAdmin" row class="ml-4">
                        <v-radio label="Yes" :value="true"></v-radio>
                        <v-radio label="No" :value="false"></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="4">
                  <v-subheader>
                    Always display "Out of County" question, regardless of whether trip is Overnight/Out-of-State?
                  </v-subheader>
                  <v-radio-group v-model="display.outOfCounty" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="4">
                  <v-subheader
                    >Display option to identify trip as "Out of County" or "Out of District" on these trip
                    types:</v-subheader
                  >
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.outOfCountyTypes"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Require submitter answers Overnight/Out-of-State question?</v-subheader>
                  <v-radio-group v-model="display.overnightOOSRequired" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Actual Time TBD" message under trip date/time? </v-subheader>
                  <v-radio-group v-model="display.actualTimeTBD" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Destination
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12">
                  <v-subheader>Display Stops on the Way and Return after Destination? </v-subheader>
                  <v-radio-group v-model="display.additionalStops" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader
                    >Allow users to request destinations not listed in Common Destinations table?</v-subheader
                  >
                  <v-radio-group v-model="display.newDestination" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Attendees
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12">
                  <v-subheader> Display "Grade Level" option? </v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.gradeLevel"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader> Display "Description of group" section? </v-subheader>
                  <v-radio-group v-model="display.groupDescription" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display emergency contact and phone number for these trip types: </v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.emergencyContact"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display health concerns question on these trip types: </v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.healthConcerns"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Students away during lunch" question on these trip types:</v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.awayForLunch"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Packed Lunch" question? </v-subheader>
                  <v-radio-group v-model="display.packedLunch" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Break out adults and students by gender?</v-subheader>
                  <v-radio-group v-model="display.splitAttendeesByGender" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                  <template v-if="display.splitAttendeesByGender">
                    <v-subheader>Include an option for non-binary?</v-subheader>
                    <v-radio-group v-model="display.nonBinary" row class="ml-4">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </template>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Number of Special Needs Students" field?</v-subheader>
                  <v-radio-group v-model="display.numSpecialNeedsStudents" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Transportation
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12">
                  <v-subheader>Display "Do you need a driver assigned/provided" question?</v-subheader>
                  <v-radio-group v-model="display.driverAssigned" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                  <template v-if="display.driverAssigned">
                    <v-subheader
                      >Require ""Do you need a driver assigned/provided" question?" to be answered?</v-subheader
                    >
                    <v-radio-group v-model="display.requireDriverInfo" row class="ml-4">
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </template>
                </v-col>

                <v-col cols="12">
                  <v-subheader>Display "External Transportation" question?</v-subheader>
                  <v-radio-group v-model="display.extTransportation" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display option to indicate Drop-off Only?</v-subheader>
                  <v-radio-group v-model="display.dropoffOnly" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display option to indicate Pickup Only?</v-subheader>
                  <v-radio-group v-model="display.pickupOnly" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Require "Comments" field?</v-subheader>
                  <v-radio-group v-model="display.comments" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader
                    >Allow split trips with two legs - Leave/Arrive and Depart/Return times for some trips?</v-subheader
                  >
                  <v-radio-group v-model="display.splitTrips" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Need Lift/Special Needs Vehicle" question?</v-subheader>
                  <v-radio-group v-model="display.specialNeedsVehicle" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "How Many Vehicles Need a Lift/Special Needs Vehicle" field?</v-subheader>
                  <v-radio-group v-model="display.numSpecialNeedsVehicle" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Comments Concerning Vehicle Needs" section? </v-subheader>
                  <v-radio-group v-model="display.commentsConcerningVehicle" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display Arrive/Depart Venue Date/Times on these trip types:</v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.venueDateTime"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader
                    >Display "Send Quotes" button to end user when vehicle type of Contractor/Approved Charter is
                    selected?
                  </v-subheader>
                  <v-radio-group v-model="display.sendQuotes" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display list of contractors to the trip requester? </v-subheader>
                  <v-radio-group v-model="display.contractors" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                  <v-subheader v-if="display.contractors">
                    Require selection of Contractor Name/Approved Charter Company before the requester can submit a
                    trip?
                  </v-subheader>
                  <v-radio-group v-model="display.requireContractors" row class="ml-4" v-if="display.contractors">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>
                    Allow edit of Vehicle Pickup and Return Date/Times by non-admin and non-owner users?
                  </v-subheader>
                  <v-radio-group v-model="display.nonAdminEditVehicleDateTime" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Funding
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row dense class="my-2">
                <v-col cols="12">
                  <v-subheader>Display "Third Party Payment" information?</v-subheader>
                  <v-radio-group v-model="display.thirdPartyPayment" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" v-show="display.thirdPartyPayment">
                  <v-subheader>Display "Third Party Payment" information on which trip types?</v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      :key="i"
                      :label="type.name"
                      :value="type.id"
                      @change="handleFundingSourceRequired(type.id, $event)"
                      class="checkbox ml-4"
                      multiple
                      v-for="(type, i) in tripTypes"
                      v-model="display.thirdPartyPaymentTripTypes"
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader v-if="display.thirdPartyPayment">
                    Would you like to include "P-Card" in your pay options?
                  </v-subheader>
                  <v-radio-group v-if="display.thirdPartyPayment" v-model="display.pcardOption" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                  <v-row dense>
                    <v-col cols="12" md="10">
                      <user-select
                        v-if="display.pcardOption"
                        v-model="display.notifyPCard"
                        :users="users"
                        label="P-Card Email Notification User"
                        noChips
                        saveEmail
                        @handleUserSelect="display.notifyPCard = $event"
                      >
                      </user-select>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col cols="12" md="3">
                  <v-subheader>Show Funding selections for Requesters?</v-subheader>

                  <v-radio-group
                    @change="display.showFunding ? null : (display.requesterFundingSourceRequired = [])"
                    class="ml-4"
                    row
                    v-model="display.showFunding"
                  >
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col cols="12" md="9">
                  <v-subheader>Funding Source is required by Requesters for these trip types:</v-subheader>

                  <div class="checkbox-wrapper">
                    <v-checkbox
                      :disabled="!display.showFunding"
                      :key="i"
                      :label="type.name"
                      :value="type.id"
                      @change="() => handleRequesterFundingSourceRequiredChange(type.id)"
                      class="checkbox ml-4"
                      multiple
                      v-for="(type, i) in tripTypes"
                      v-model="display.requesterFundingSourceRequired"
                    ></v-checkbox>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-subheader>Funding Source is required by Approvers for these trip types:</v-subheader>

                  <div class="checkbox-wrapper">
                    <v-checkbox
                      :disabled="approverFundingSourceRequiredDisabled(type.id)"
                      :key="i"
                      :label="type.name"
                      :value="type.id"
                      class="checkbox ml-4"
                      multiple
                      v-for="(type, i) in tripTypes"
                      v-model="display.approverFundingSourceRequired"
                    ></v-checkbox>
                  </div>
                </v-col>

                <v-col cols="12">
                  <v-subheader>Maximum number of funding source allowed:</v-subheader>
                  <v-text-field
                    row
                    outlined
                    hide-details
                    v-model="display.fundingSourceLimit"
                    label="Funding Source Limit"
                    type="number"
                    min="1"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-subheader>
                    Require Budget Codes on Funding Sources to be listed in Budget Code Table?
                  </v-subheader>
                  <v-radio-group v-model="display.requireBCFromTable" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>
                    Allow admins to select any Funding Source on trip regardless of the Funding Source being Location
                    specific?
                  </v-subheader>
                  <v-radio-group v-model="display.showAllFS" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Budget Code is required for Approvers for these trip types:</v-subheader>
                  <v-row v-for="(type, i) in tripTypes" :key="i" class="px-4" dense>
                    <v-col cols="12" md="6">
                      <v-checkbox
                        v-model="display.budgetCodeRequired[type.id].required"
                        :label="type.name"
                      ></v-checkbox>
                    </v-col>
                    <v-col cols="12" md="6">
                      <v-select
                        label="Level Budget Code Required"
                        :items="approvalLevels"
                        item-value="id"
                        item-text="name"
                        v-model="display.budgetCodeRequired[type.id].level"
                        outlined
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Show message when allotment is depleted on Funding Source?</v-subheader>
                  <v-radio-group v-model="display.allotmentDepleted" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Additional Information
            </v-expansion-panel-header>
            <v-expansion-panel-content
              ><v-row dense class="my-2">
                <v-col cols="12">
                  <v-subheader> Display "Educational Objective of Trip" section? </v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.educationalObjective"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Do you need a substitute" question?</v-subheader>
                  <v-radio-group v-model="display.substitute" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                  <v-subheader v-if="display.substitute">
                    Require "Do you need a substitute" question to be answered?
                  </v-subheader>
                  <v-radio-group v-model="display.substituteRequired" row class="ml-4" v-if="display.substitute">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display permission slip section on these trip types:</v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.permissionSlip"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                  </div>
                </v-col>
                <v-col cols="12">
                  <v-subheader>Display "Special Indicators" on these trip types:</v-subheader>
                  <div class="checkbox-wrapper">
                    <v-checkbox
                      class="checkbox ml-4"
                      v-for="(type, i) in tripTypes"
                      :key="i"
                      :value="type.id"
                      v-model="display.specialIndicators"
                      :label="type.name"
                      multiple
                    ></v-checkbox>
                    <v-subheader v-if="display.specialIndicators.length > 0" class="full-width">
                      Require at least one special indicator to be selected?
                    </v-subheader>
                    <v-radio-group
                      v-model="display.specialIndicatorsRequired"
                      row
                      class="ml-4"
                      v-if="display.specialIndicators.length > 0"
                    >
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                    <v-subheader v-if="display.specialIndicators.length > 0" class="full-width">
                      Display special indicators to administrators only?
                    </v-subheader>
                    <v-radio-group
                      v-model="display.specialIndicatorsAdmin"
                      row
                      class="ml-4"
                      v-if="display.specialIndicators.length > 0"
                    >
                      <v-radio label="Yes" :value="true"></v-radio>
                      <v-radio label="No" :value="false"></v-radio>
                    </v-radio-group>
                  </div>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <!-- <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Supporting Documents
            </v-expansion-panel-header>
            <v-expansion-panel-content>
            </v-expansion-panel-content>
          </v-expansion-panel> -->
          <v-expansion-panel>
            <v-expansion-panel-header class="text-uppercase font-weight-medium grey lighten-3">
              Miscellaneous
            </v-expansion-panel-header>
            <v-expansion-panel-content
              ><v-row dense class="my-2">
                <v-col cols="12">
                  <v-subheader>Display "Create Duplicate Request" button to end user?</v-subheader>
                  <v-radio-group v-model="display.duplicate" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                </v-col>
                <v-col cols="12" md="12">
                  <v-subheader>
                    Limit time before trip when it can be canceled or rescheduled by the requester?
                  </v-subheader>
                  <v-radio-group v-model="display.limitReschedule" row class="ml-4">
                    <v-radio label="Yes" :value="true"></v-radio>
                    <v-radio label="No" :value="false"></v-radio>
                  </v-radio-group>
                  <v-text-field
                    outlined
                    v-if="display.limitReschedule"
                    v-model="display.limitRescheduleDays"
                    label="Days Notice Required"
                    type="number"
                    min="0"
                  ></v-text-field>
                  <v-spacer></v-spacer>
                  <v-textarea
                    outlined
                    rows="1"
                    auto-grow
                    label="Cancelation / Reschedule Limit Message"
                    v-model="display.limitRescheduleMessage"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </template>
    </v-form>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { GET_CONFIG, SET_CONFIG } from '@/store/modules/Config/actions';
import UserSelect from '@/components/UserSelect.vue';

export default {
  inject: ['eventHub'],
  components: { UserSelect },
  data: () => ({
    loading: false,
    requesterFundingSourceRequiredMap: {},
    display: {
      vehDriverInfo: false,
      requireDriverInfo: false,
      staffCategory: true,
      outOfCounty: true,
      overnightOOSRequired: false,
      gradeLevel: [],
      groupDescription: true,
      educationalObjective: [1],
      emergencyContact: [],
      driverAssigned: false,
      substitute: false,
      substituteRequired: false,
      extTransportation: true,
      dropoffOnly: true,
      pickupOnly: true,
      splitTrips: true,
      healthConcerns: [],
      requireHealthConcerns: false,
      awayForLunch: [],
      packedLunch: true,
      thirdPartyPayment: true,
      pcardOption: false,
      notifyPCard: '',
      recurringTrips: true,
      permissionSlip: [],
      specialIndicators: [],
      specialIndicatorsRequired: false,
      specialIndicatorsAdmin: false,
      showFunding: true,
      requireBCFromTable: true,
      showAllFS: false,
      allotmentDepleted: true,
      requesterFundingSourceRequired: [],
      approverFundingSourceRequired: [],
      fundingSourceLimit: 1,
      budgetCodeRequired: {},
      comments: true,
      specialNeedsVehicle: true,
      numSpecialNeedsVehicle: false,
      numWheelchairSlot: true,
      numSafetyVest: true,
      numFoldDownSeat: false,
      commentsConcerningVehicle: true,
      actualTimeTBD: false,
      venueDateTime: [],
      sendQuotes: false,
      contractors: true,
      requireContractors: false,
      additionalStops: true,
      newDestination: true,
      duplicate: true,
      splitAttendeesByGender: false,
      nonBinary: true,
      numSpecialNeedsStudents: true,
      nonAdminEditVehicleDateTime: false,
      requireAttachmentsTripTypes: [],
      requireAttachmentsVehicleTypes: [],
      limitReschedule: false,
      limitRescheduleDays: 0,
      limitRescheduleMessage: '',
    },
  }),
  computed: {
    ...mapGetters('tripType', ['tripTypes']),
    ...mapGetters('vehicleType', ['vehicleTypes']),
    ...mapGetters('user', ['users', 'me', 'canEditSettings']),
    ...mapGetters('approvalLevel', ['approvalLevels']),
  },
  created() {
    this.eventHub.$on('saveDisplayHideConfig', () => this.save());
    this.fetchItems();
  },
  beforeDestroy() {
    this.eventHub.$off('saveDisplayHideConfig');
  },
  methods: {
    ...mapActions('config', [GET_CONFIG, SET_CONFIG]),
    handleRequesterFundingSourceRequiredChange(id) {
      if (this.requesterFundingSourceRequiredMap[id]) {
        delete this.requesterFundingSourceRequiredMap[id];
      } else {
        this.requesterFundingSourceRequiredMap[id] = true;
        this.display.approverFundingSourceRequired.push(id);
      }
    },
    approverFundingSourceRequiredDisabled(id) {
      return this.requesterFundingSourceRequiredMap[id];
    },
    async fetchItems() {
      this.loading = true;
      this.trConfig = await this.getConfig('tripRequestForm');

      if (this.trConfig && this.trConfig.display) {
        this.display = JSON.parse(JSON.stringify({ ...this.display, ...this.trConfig.display }));
      }

      this.tripTypes.forEach((e) => {
        if (!this.display.budgetCodeRequired[e.id]) {
          this.display.budgetCodeRequired[e.id] = { required: false, level: 0 };
        }
      });

      this.setFundingSourceRequiredValues();

      this.loading = false;
    },
    async save() {
      try {
        const value = this.trConfig ? { ...this.trConfig, display: this.display } : { display: this.display };

        if (!value.display.showFunding) value.display.requesterFundingSourceRequired = [];

        const r = await this.setConfig({ name: 'tripRequestForm', value });

        if (r && r.done) this.$myalert.success('Trip Request Form Options saved');
      } catch (error) {
        this.$myalert.error(error.message);
      }
    },
    setFundingSourceRequiredValues() {
      this.display?.requesterFundingSourceRequired?.forEach((id) => {
        this.requesterFundingSourceRequiredMap[id] = true;

        if (this.display.approverFundingSourceRequired.indexOf(id) === -1) {
          this.display.approverFundingSourceRequired.push(id);
        }
      });
    },
  },
};
</script>

<style scoped>
.save-btn {
  float: right;
  margin-bottom: 12px;
}
.checkbox-wrapper {
  display: flex;
  align-items: center !important;
  flex-wrap: wrap;
}
.checkbox {
  margin-right: 20px;
}
.bg-blue {
  background-color: aliceblue;
}
.full-width {
  width: 100%;
}
.progress {
  margin-top: 50px;
  margin-left: auto;
  margin-right: auto;
}
.v-progress-circular > svg {
  width: fit-content;
}
/* ::v-deep .col-12:nth-child(odd) {
  background-color: aliceblue;
} */
</style>
